import types from "../../data/sound-types";

export default [
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [{ type: types.SNARE }],
  [],
  [{ type: types.SNARE }],
  [],
  [{ type: types.SNARE }],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [{ type: types.SNARE }],
  [],
  [{ type: types.SNARE }],
  [],
  [{ type: types.SNARE }],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }],
  [],
  [],
  [],
  [{ type: types.SNARE }],
  [],
  [],
  [],
  [{ type: types.KICK }]
];
