import drums from './drums';
import bass from './bass';
import polySynth from './polySynth';
import lyrics from './lyrics';

const jump = {
  drums,
  bass,
  polySynth,
  lyrics
};

export default jump;
