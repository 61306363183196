import notes from '../../data/note-frequencies';

export default [
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.F, octave: -1, duration: 1 }, { note: notes.F, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.F, octave: -1, duration: 1 }, { note: notes.F, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.G, octave: -1, duration: 1 }, { note: notes.G, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.G, octave: -1, duration: 1 }, { note: notes.G, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.F, octave: -1, duration: 1 }, { note: notes.F, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.G, octave: -1, duration: 1 }, { note: notes.G, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.F, octave: -1, duration: 1 }, { note: notes.F, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.F, octave: -1, duration: 1 }, { note: notes.F, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.F, octave: -1, duration: 1 }, { note: notes.F, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.G, octave: -1, duration: 1 }, { note: notes.G, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.G, octave: -1, duration: 1 }, { note: notes.G, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.F, octave: -1, duration: 1 }, { note: notes.F, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.G, octave: -1, duration: 1 }, { note: notes.G, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.F, octave: -1, duration: 1 }, { note: notes.F, octave: -2, duration: 1 }], 
  [],
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.F, octave: -1, duration: 1 }, { note: notes.F, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.F, octave: -1, duration: 1 }, { note: notes.F, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.G, octave: -1, duration: 1 }, { note: notes.G, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.G, octave: -1, duration: 1 }, { note: notes.G, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.F, octave: -1, duration: 1 }, { note: notes.F, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.G, octave: -1, duration: 1 }, { note: notes.G, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.F, octave: -1, duration: 1 }, { note: notes.F, octave: -2, duration: 1 }], 
  [],
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.F, octave: -1, duration: 1 }, { note: notes.F, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.F, octave: -1, duration: 1 }, { note: notes.F, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.G, octave: -1, duration: 1 }, { note: notes.G, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.G, octave: -1, duration: 1 }, { note: notes.G, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.F, octave: -1, duration: 1 }, { note: notes.F, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.G, octave: -1, duration: 1 }, { note: notes.G, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.F, octave: -1, duration: 1 }, { note: notes.F, octave: -2, duration: 1 }], 
  [],
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.F, octave: -1, duration: 1 }, { note: notes.F, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.F, octave: -1, duration: 1 }, { note: notes.F, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.G, octave: -1, duration: 1 }, { note: notes.G, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.G, octave: -1, duration: 1 }, { note: notes.G, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.F, octave: -1, duration: 1 }, { note: notes.F, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.G, octave: -1, duration: 1 }, { note: notes.G, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.F, octave: -1, duration: 1 }, { note: notes.F, octave: -2, duration: 1 }], 
  [],
  [{ note: notes.A, octave: -2, duration: 5 }, { note: notes.A, octave: -3, duration: 5 }], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [{ note: notes.G, octave: -2, duration: 5 }, { note: notes.G, octave: -3, duration: 5 }], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [{ note: notes.A, octave: -2, duration: 5 }, { note: notes.A, octave: -3, duration: 5 }], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [{ note: notes.G, octave: -2, duration: 5 }, { note: notes.G, octave: -3, duration: 5 }], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [{ note: notes.A, octave: -2, duration: 3 }, { note: notes.A, octave: -3, duration: 3 }], 
  [], 
  [], 
  [], 
  [{ note: notes.G, octave: -2, duration: 3 }, { note: notes.G, octave: -3, duration: 3 }], 
  [], 
  [], 
  [], 
  [{ note: notes.F, octave: -2, duration: 1 }, { note: notes.F, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.F, octave: -2, duration: 1 }, { note: notes.F, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.F, octave: -2, duration: 1 }, { note: notes.F, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.F, octave: -2, duration: 1 }, { note: notes.F, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.E, octave: -2, duration: 1 }, { note: notes.E, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.E, octave: -2, duration: 1 }, { note: notes.E, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.E, octave: -2, duration: 1 }, { note: notes.E, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.D, octave: -2, duration: 1 }, { note: notes.D, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.D, octave: -2, duration: 1 }, { note: notes.D, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.D, octave: -2, duration: 1 }, { note: notes.D, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.D, octave: -2, duration: 1 }, { note: notes.D, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.D, octave: -2, duration: 1 }, { note: notes.D, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.D, octave: -2, duration: 1 }, { note: notes.D, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.D, octave: -2, duration: 1 }, { note: notes.D, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.D, octave: -2, duration: 1 }, { note: notes.D, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.D, octave: -2, duration: 1 }, { note: notes.D, octave: -3, duration: 1 }],
  [],
  [{ note: notes.F, octave: -2, duration: 1 }, { note: notes.F, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.F, octave: -2, duration: 1 }, { note: notes.F, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.F, octave: -2, duration: 1 }, { note: notes.F, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.F, octave: -2, duration: 1 }, { note: notes.F, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.E, octave: -2, duration: 1 }, { note: notes.E, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.E, octave: -2, duration: 1 }, { note: notes.E, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.E, octave: -2, duration: 1 }, { note: notes.E, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.D, octave: -2, duration: 1 }, { note: notes.D, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.D, octave: -2, duration: 1 }, { note: notes.D, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.D, octave: -2, duration: 1 }, { note: notes.D, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.D, octave: -2, duration: 1 }, { note: notes.D, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.D, octave: -2, duration: 1 }, { note: notes.D, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.D, octave: -2, duration: 1 }, { note: notes.D, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.D, octave: -2, duration: 1 }, { note: notes.D, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.D, octave: -2, duration: 1 }, { note: notes.D, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.D, octave: -2, duration: 1 }, { note: notes.D, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.F, octave: -2, duration: 1 }, { note: notes.F, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.F, octave: -2, duration: 1 }, { note: notes.F, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.F, octave: -2, duration: 1 }, { note: notes.F, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.F, octave: -2, duration: 1 }, { note: notes.F, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.E, octave: -2, duration: 1 }, { note: notes.E, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.E, octave: -2, duration: 1 }, { note: notes.E, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.E, octave: -2, duration: 1 }, { note: notes.E, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.G, octave: -2, duration: 1 }, { note: notes.G, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.G, octave: -2, duration: 1 }, { note: notes.G, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.G, octave: -2, duration: 1 }, { note: notes.G, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.G, octave: -2, duration: 1 }, { note: notes.G, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.G, octave: -2, duration: 1 }, { note: notes.G, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.G, octave: -2, duration: 1 }, { note: notes.G, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.G, octave: -2, duration: 1 }, { note: notes.G, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.G, octave: -2, duration: 1 }, { note: notes.G, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.G, octave: -2, duration: 1 }, { note: notes.G, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.G, octave: -2, duration: 1 }, { note: notes.G, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.G, octave: -2, duration: 1 }, { note: notes.G, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.G, octave: -2, duration: 1 }, { note: notes.G, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.G, octave: -2, duration: 1 }, { note: notes.G, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.G, octave: -2, duration: 1 }, { note: notes.G, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.G, octave: -2, duration: 1 }, { note: notes.G, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.G, octave: -2, duration: 1 }, { note: notes.G, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.G, octave: -2, duration: 1 }, { note: notes.G, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.F, octave: -1, duration: 1 }, { note: notes.F, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.F, octave: -1, duration: 1 }, { note: notes.F, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.G, octave: -1, duration: 1 }, { note: notes.G, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.G, octave: -1, duration: 1 }, { note: notes.G, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.F, octave: -1, duration: 1 }, { note: notes.F, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.G, octave: -1, duration: 1 }, { note: notes.G, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.F, octave: -1, duration: 1 }, { note: notes.F, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.F, octave: -1, duration: 1 }, { note: notes.F, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.F, octave: -1, duration: 1 }, { note: notes.F, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.G, octave: -1, duration: 1 }, { note: notes.G, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.G, octave: -1, duration: 1 }, { note: notes.G, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.F, octave: -1, duration: 1 }, { note: notes.F, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.G, octave: -1, duration: 1 }, { note: notes.G, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.F, octave: -1, duration: 1 }, { note: notes.F, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.F, octave: -1, duration: 1 }, { note: notes.F, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.F, octave: -1, duration: 1 }, { note: notes.F, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.G, octave: -1, duration: 1 }, { note: notes.G, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.G, octave: -1, duration: 1 }, { note: notes.G, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.F, octave: -1, duration: 1 }, { note: notes.F, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.G, octave: -1, duration: 1 }, { note: notes.G, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.F, octave: -1, duration: 1 }, { note: notes.F, octave: -2, duration: 1 }], 
  [],
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.F, octave: -1, duration: 1 }, { note: notes.F, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.F, octave: -1, duration: 1 }, { note: notes.F, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.G, octave: -1, duration: 1 }, { note: notes.G, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.G, octave: -1, duration: 1 }, { note: notes.G, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.F, octave: -1, duration: 1 }, { note: notes.F, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.G, octave: -1, duration: 1 }, { note: notes.G, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.F, octave: -1, duration: 1 }, { note: notes.F, octave: -2, duration: 1 }], 
  [],
  [{ note: notes.A, octave: -2, duration: 5 }, { note: notes.A, octave: -3, duration: 5 }], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [{ note: notes.G, octave: -2, duration: 5 }, { note: notes.G, octave: -3, duration: 5 }], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [{ note: notes.A, octave: -2, duration: 5 }, { note: notes.A, octave: -3, duration: 5 }], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [{ note: notes.G, octave: -2, duration: 5 }, { note: notes.G, octave: -3, duration: 5 }], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [{ note: notes.A, octave: -2, duration: 3 }, { note: notes.A, octave: -3, duration: 3 }], 
  [], 
  [], 
  [], 
  [{ note: notes.G, octave: -2, duration: 3 }, { note: notes.G, octave: -3, duration: 3 }], 
  [], 
  [], 
  [], 
  [{ note: notes.F, octave: -2, duration: 1 }, { note: notes.F, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.F, octave: -2, duration: 1 }, { note: notes.F, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.F, octave: -2, duration: 1 }, { note: notes.F, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.F, octave: -2, duration: 1 }, { note: notes.F, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.E, octave: -2, duration: 1 }, { note: notes.E, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.E, octave: -2, duration: 1 }, { note: notes.E, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.E, octave: -2, duration: 1 }, { note: notes.E, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.D, octave: -2, duration: 1 }, { note: notes.D, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.D, octave: -2, duration: 1 }, { note: notes.D, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.D, octave: -2, duration: 1 }, { note: notes.D, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.D, octave: -2, duration: 1 }, { note: notes.D, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.D, octave: -2, duration: 1 }, { note: notes.D, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.D, octave: -2, duration: 1 }, { note: notes.D, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.D, octave: -2, duration: 1 }, { note: notes.D, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.D, octave: -2, duration: 1 }, { note: notes.D, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.D, octave: -2, duration: 1 }, { note: notes.D, octave: -3, duration: 1 }],
  [],
  [{ note: notes.F, octave: -2, duration: 1 }, { note: notes.F, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.F, octave: -2, duration: 1 }, { note: notes.F, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.F, octave: -2, duration: 1 }, { note: notes.F, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.F, octave: -2, duration: 1 }, { note: notes.F, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.E, octave: -2, duration: 1 }, { note: notes.E, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.E, octave: -2, duration: 1 }, { note: notes.E, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.E, octave: -2, duration: 1 }, { note: notes.E, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.D, octave: -2, duration: 1 }, { note: notes.D, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.D, octave: -2, duration: 1 }, { note: notes.D, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.D, octave: -2, duration: 1 }, { note: notes.D, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.D, octave: -2, duration: 1 }, { note: notes.D, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.D, octave: -2, duration: 1 }, { note: notes.D, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.D, octave: -2, duration: 1 }, { note: notes.D, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.D, octave: -2, duration: 1 }, { note: notes.D, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.D, octave: -2, duration: 1 }, { note: notes.D, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.D, octave: -2, duration: 1 }, { note: notes.D, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.F, octave: -2, duration: 1 }, { note: notes.F, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.F, octave: -2, duration: 1 }, { note: notes.F, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.F, octave: -2, duration: 1 }, { note: notes.F, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.F, octave: -2, duration: 1 }, { note: notes.F, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.E, octave: -2, duration: 1 }, { note: notes.E, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.E, octave: -2, duration: 1 }, { note: notes.E, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.E, octave: -2, duration: 1 }, { note: notes.E, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.G, octave: -2, duration: 1 }, { note: notes.G, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.G, octave: -2, duration: 1 }, { note: notes.G, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.G, octave: -2, duration: 1 }, { note: notes.G, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.G, octave: -2, duration: 1 }, { note: notes.G, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.G, octave: -2, duration: 1 }, { note: notes.G, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.G, octave: -2, duration: 1 }, { note: notes.G, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.G, octave: -2, duration: 1 }, { note: notes.G, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.G, octave: -2, duration: 1 }, { note: notes.G, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.G, octave: -2, duration: 1 }, { note: notes.G, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.G, octave: -2, duration: 1 }, { note: notes.G, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.G, octave: -2, duration: 1 }, { note: notes.G, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.G, octave: -2, duration: 1 }, { note: notes.G, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.G, octave: -2, duration: 1 }, { note: notes.G, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.G, octave: -2, duration: 1 }, { note: notes.G, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.G, octave: -2, duration: 1 }, { note: notes.G, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.G, octave: -2, duration: 1 }, { note: notes.G, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.G, octave: -2, duration: 1 }, { note: notes.G, octave: -3, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.F, octave: -1, duration: 1 }, { note: notes.F, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.F, octave: -1, duration: 1 }, { note: notes.F, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.G, octave: -1, duration: 1 }, { note: notes.G, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.G, octave: -1, duration: 1 }, { note: notes.G, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.F, octave: -1, duration: 1 }, { note: notes.F, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.G, octave: -1, duration: 1 }, { note: notes.G, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.F, octave: -1, duration: 1 }, { note: notes.F, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.F, octave: -1, duration: 1 }, { note: notes.F, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.F, octave: -1, duration: 1 }, { note: notes.F, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.G, octave: -1, duration: 1 }, { note: notes.G, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.G, octave: -1, duration: 1 }, { note: notes.G, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.F, octave: -1, duration: 1 }, { note: notes.F, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.G, octave: -1, duration: 1 }, { note: notes.G, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.F, octave: -1, duration: 1 }, { note: notes.F, octave: -2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: -1, duration: 1 }, { note: notes.C, octave: -2, duration: 1 }], 
];