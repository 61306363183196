export default [
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [{ nextLine: true }],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [{ text: 'I ' }],
  [],
  [{ text: 'get ' }],
  [],
  [{ text: 'up ' }],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [{ nextLine: true }],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [{ text: 'And ' }],
  [],
  [],
  [{ text: 'nothing ' }],
  [],
  [],
  [{ text: 'gets ' }],
  [],
  [{ text: 'me ' }],
  [],
  [{ text: 'down ' }],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [{ nextLine: true }],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [{ text: 'You ' }],
  [],
  [{ text: 'got ' }],
  [],
  [{ text: 'it ' }],
  [],
  [{ text: 'tough ' }],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [{ nextLine: true }],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [{ text: "I've  " }],
  [],
  [],
  [{ text: 'seen ' }],
  [],
  [{ text: 'the ' }],
  [],
  [{ text: 'toughest ' }],
  [],
  [],
  [{ text: 'around ' }],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [{ nextLine: true }],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [{ text: 'And ' }],
  [],
  [],
  [{ text: 'I ' }],
  [],
  [{ text: 'know ' }],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [{ nextLine: true }],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [{ text: 'Just ' }],
  [],
  [{ text: 'how ' }],
  [],
  [{ text: 'you ' }],
  [],
  [{ text: 'feel ' }],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [{ nextLine: true }],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [{ text: 'You ' }],
  [],
  [{ text: 'got ' }],
  [],
  [{ text: 'to ' }],
  [],
  [{ text: 'roll ' }],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [{ text: 'with ' }],
  [],
  [{ text: 'the ' }],
  [],
  [{ text: 'punches ' }],
  [],
  [],
  [],
  [{ nextLine: true }],
  [],
  [],
  [],
  [{ text: 'And ' }],
  [],
  [{ text: 'get ' }],
  [],
  [{ text: 'to ' }],
  [],
  [{ text: "what's " }],
  [],
  [{ text: 'real ' }],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [{ nextLine: true }],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [{ nextLine: true }],
  [{ text: "Can't " }],
  [],
  [{ text: 'you ' }],
  [],
  [{ text: 'see ' }],
  [],
  [{ text: 'me ' }],
  [],
  [{ text: 'standing ' }],
  [],
  [{ text: 'here ' }],
  [],
  [],
  [{ text: "i've " }],
  [],
  [{ text: 'got ' }],
  [],
  [{ text: 'my ' }],
  [],
  [{ text: 'back ' }],
  [],
  [{ text: 'against  ' }],
  [],
  [],
  [{ text: 'the ' }],
  [],
  [],
  [],
  [{ text: 'record ' }],
  [],
  [],
  [{ text: 'machine ' }],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [{ nextLine: true }],
  [],
  [],
  [],
  [],
  [{ text: 'I ' }],
  [],
  [{ text: "ain't " }],
  [],
  [{ text: 'the ' }],
  [],
  [{ text: 'worst ' }],
  [],
  [],
  [{ text: 'that  ' }],
  [],
  [{ text: "you've " }],
  [],
  [{ text: 'seen ' }],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [{ nextLine: true }],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [{ text: 'Oh ' }],
  [],
  [{ text: "can't " }],
  [],
  [{ text: 'you ' }],
  [],
  [{ text: 'see ' }],
  [],
  [],
  [{ text: 'what  ' }],
  [],
  [{ text: 'I ' }],
  [],
  [],
  [{ text: 'mean? ' }],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [{ nextLine: true }],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [{ text: 'Might ' }],
  [],
  [{ text: 'as ' }],
  [],
  [{ text: 'well ' }],
  [],
  [{ text: 'jump ' }],
  [],
  [{ nextLine: true }],
  [],
  [],
  [{ text: 'JUMP! ' }],
  [],
  [],
  [],
  [{ nextLine: true }],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [{ text: 'Might ' }],
  [],
  [{ text: 'as ' }],
  [],
  [{ text: 'well ' }],
  [],
  [{ text: 'jump ' }],
  [],
  [{ nextLine: true }],
  [],
  [],
  [],
  [],
  [],
  [],
  [{ nextLine: true }],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [{ text: 'Go ' }],
  [],
  [{ text: 'ahead ' }],
  [],
  [{ text: 'and ' }],
  [],
  [{ text: 'jump ' }],
  [],
  [{ nextLine: true }],
  [],
  [],
  [],
  [{ text: 'JUMP! ' }],
  [],
  [],
  [],
  [{ nextLine: true }],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [{ text: 'Go ' }],
  [],
  [{ text: 'ahead ' }],
  [],
  [{ text: 'and ' }],
  [],
  [{ text: 'jump ' }],
  [],
  [],
  [],
  [],
  [],
  [{ nextLine: true }],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [{ text: 'Woah ' }],
  [{ text: 'Oh! ' }],
  [],
  [],
  [],
  [],
  [{ text: 'Hey ' }],
  [{ text: 'you! ' }],
  [],
  [],
  [],
  [],
  [],
  [{ text: 'Who ' }],
  [],
  [{ text: 'said ' }],
  [],
  [{ text: 'that? ' }],
  [],
  [],
  [{ nextLine: true }],
  [],
  [],
  [],
  [],
  [],
  [{ text: 'Hey ' }],
  [],
  [],
  [{ text: 'how ' }],
  [],
  [{ text: 'you ' }],
  [],
  [{ text: 'been? ' }],
  [],
  [],
  [],
  [{ nextLine: true }],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [{ text: 'You ' }],
  [],
  [{ text: 'say ' }],
  [],
  [{ text: 'you ' }],
  [],
  [{ text: "don't " }],
  [],
  [],
  [],
  [{ text: 'know ' }],
  [],
  [{ nextLine: true }],
  [],
  [],
  [],
  [],
  [],
  [{ text: 'You ' }],
  [],
  [{ text: "won't " }],
  [],
  [{ text: 'know ' }],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [{ text: 'until ' }],
  [],
  [{ text: 'you ' }],
  [],
  [{ text: 'begin ' }],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [{ nextLine: true }],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [{ text: "Can't " }],
  [],
  [{ text: 'you ' }],
  [],
  [{ text: 'see ' }],
  [],
  [{ text: 'me ' }],
  [],
  [{ text: 'standing ' }],
  [],
  [{ text: 'here ' }],
  [],
  [],
  [{ text: "i've " }],
  [],
  [{ text: 'got ' }],
  [],
  [{ text: 'my ' }],
  [],
  [{ text: 'back ' }],
  [],
  [{ text: 'against  ' }],
  [],
  [],
  [{ text: 'the ' }],
  [],
  [],
  [],
  [{ text: 'record ' }],
  [],
  [],
  [{ text: 'machine ' }],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [{ nextLine: true }],
  [],
  [],
  [],
  [],
  [{ text: 'I ' }],
  [],
  [{ text: "ain't " }],
  [],
  [{ text: 'the ' }],
  [],
  [{ text: 'worst ' }],
  [],
  [],
  [{ text: 'that  ' }],
  [],
  [{ text: "you've " }],
  [],
  [{ text: 'seen ' }],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [{ nextLine: true }],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [{ text: 'Oh ' }],
  [],
  [{ text: "can't " }],
  [],
  [{ text: 'you ' }],
  [],
  [{ text: 'see ' }],
  [],
  [],
  [{ text: 'what  ' }],
  [],
  [{ text: 'I ' }],
  [],
  [],
  [{ text: 'mean? ' }],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [{ nextLine: true }],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [{ text: 'Might ' }],
  [],
  [{ text: 'as ' }],
  [],
  [{ text: 'well ' }],
  [],
  [{ text: 'jump ' }],
  [],
  [{ nextLine: true }],
  [],
  [],
  [{ text: 'JUMP! ' }],
  [],
  [],
  [],
  [{ nextLine: true }],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [{ text: 'Might ' }],
  [],
  [{ text: 'as ' }],
  [],
  [{ text: 'well ' }],
  [],
  [{ text: 'jump ' }],
  [],
  [{ nextLine: true }],
  [],
  [],
  [],
  [],
  [],
  [],
  [{ nextLine: true }],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [{ text: 'Go ' }],
  [],
  [{ text: 'ahead ' }],
  [],
  [{ text: 'and ' }],
  [],
  [{ text: 'jump ' }],
  [],
  [{ nextLine: true }],
  [],
  [],
  [],
  [{ text: 'JUMP! ' }],
  [],
  [],
  [],
  [{ nextLine: true }],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [{ text: 'Go ' }],
  [],
  [{ text: 'ahead ' }],
  [],
  [{ text: 'and ' }],
  [],
  [{ text: 'jump ' }],
  [],
  [],
  [],
  [],
  [],
  [{ nextLine: true }],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  []
];
