import notes from '../../data/note-frequencies';

export default [
  [], 
  [], 
  [], 
  [], 
  [{ note: notes.G, octave: 1, duration: 1 }, { note: notes.B, octave: 1, duration: 1 }, { note: notes.D, octave: 2, duration: 1 }],
  [], 
  [], 
  [], 
  [], 
  [], 
  [{ note: notes.G, octave: 1, duration: 1 }, { note: notes.C, octave: 2, duration: 1 }, { note: notes.E, octave: 2, duration: 1 }], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [{ note: notes.F, octave: 1, duration: 1 }, { note: notes.A, octave: 1, duration: 1 }, { note: notes.C, octave: 2, duration: 1 }], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [{ note: notes.F, octave: 1, duration: 1 }, { note: notes.A, octave: 1, duration: 1 }, { note: notes.C, octave: 2, duration: 1 }], 
  [], 
  [], 
  [], 
  [{ note: notes.G, octave: 1, duration: 1 }, { note: notes.B, octave: 1, duration: 1 }, { note: notes.D, octave: 2, duration: 1 }], 
  [], 
  [], 
  [], 
  [{ note: notes.G, octave: 1, duration: 2.5 }, { note: notes.B, octave: 1, duration: 2.5 }, { note: notes.D, octave: 2, duration: 2.5 }], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [{ note: notes.G, octave: 1, duration: 1 }, { note: notes.C, octave: 2, duration: 1 }, { note: notes.E, octave: 2, duration: 1 }], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [{ note: notes.F, octave: 1, duration: 1 }, { note: notes.A, octave: 1, duration: 1 }, { note: notes.C, octave: 2, duration: 1 }], 
  [], 
  [], 
  [], 
  [{ note: notes.F, octave: 1, duration: 1 }, { note: notes.A, octave: 1, duration: 1 }, { note: notes.C, octave: 2, duration: 1 }], 
  [], 
  [], 
  [], 
  [{ note: notes.C, octave: 0, duration: 1 }, { note: notes.F, octave: 1, duration: 1 }, { note: notes.A, octave: 1, duration: 1 }], 
  [], 
  [], 
  [],  
  [{ note: notes.C, octave: 0, duration: 6 }, { note: notes.F, octave: 1, duration: 6 }, { note: notes.G, octave: 1, duration: 6 }], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [{ note: notes.G, octave: 1, duration: 1 }, { note: notes.B, octave: 1, duration: 1 }, { note: notes.D, octave: 2, duration: 1 }], //4
  [], 
  [], 
  [], 
  [], 
  [], 
  [{ note: notes.G, octave: 1, duration: 1 }, { note: notes.C, octave: 2, duration: 1 }, { note: notes.E, octave: 2, duration: 1 }], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [{ note: notes.F, octave: 1, duration: 1 }, { note: notes.A, octave: 1, duration: 1 }, { note: notes.C, octave: 2, duration: 1 }], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [{ note: notes.F, octave: 1, duration: 1 }, { note: notes.A, octave: 1, duration: 1 }, { note: notes.C, octave: 2, duration: 1 }], 
  [], 
  [], 
  [], 
  [{ note: notes.G, octave: 1, duration: 1 }, { note: notes.B, octave: 1, duration: 1 }, { note: notes.D, octave: 2, duration: 1 }], 
  [], 
  [], 
  [], 
  [{ note: notes.G, octave: 1, duration: 2.5 }, { note: notes.B, octave: 1, duration: 2.5 }, { note: notes.D, octave: 2, duration: 2.5 }], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [{ note: notes.G, octave: 1, duration: 1 }, { note: notes.C, octave: 2, duration: 1 }, { note: notes.E, octave: 2, duration: 1 }], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [{ note: notes.F, octave: 1, duration: 1 }, { note: notes.A, octave: 1, duration: 1 }, { note: notes.C, octave: 2, duration: 1 }], 
  [], 
  [], 
  [], 
  [{ note: notes.F, octave: 1, duration: 1 }, { note: notes.A, octave: 1, duration: 1 }, { note: notes.C, octave: 2, duration: 1 }], 
  [], 
  [], 
  [], 
  [{ note: notes.C, octave: 0, duration: 1 }, { note: notes.F, octave: 1, duration: 1 }, { note: notes.A, octave: 1, duration: 1 }], 
  [], 
  [], 
  [],  
  [{ note: notes.C, octave: 0, duration: 6 }, { note: notes.F, octave: 1, duration: 6 }, { note: notes.G, octave: 1, duration: 6 }], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [],
  [], 
  [], 
  [], 
  [], 
  [{ note: notes.G, octave: 1, duration: 1 }, { note: notes.B, octave: 1, duration: 1 }, { note: notes.D, octave: 2, duration: 1 }], //4
  [], 
  [], 
  [], 
  [], 
  [], 
  [{ note: notes.G, octave: 1, duration: 1 }, { note: notes.C, octave: 2, duration: 1 }, { note: notes.E, octave: 2, duration: 1 }], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [{ note: notes.F, octave: 1, duration: 1 }, { note: notes.A, octave: 1, duration: 1 }, { note: notes.C, octave: 2, duration: 1 }], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [{ note: notes.F, octave: 1, duration: 1 }, { note: notes.A, octave: 1, duration: 1 }, { note: notes.C, octave: 2, duration: 1 }], 
  [], 
  [], 
  [], 
  [{ note: notes.G, octave: 1, duration: 1 }, { note: notes.B, octave: 1, duration: 1 }, { note: notes.D, octave: 2, duration: 1 }], 
  [], 
  [], 
  [], 
  [{ note: notes.G, octave: 1, duration: 2.5 }, { note: notes.B, octave: 1, duration: 2.5 }, { note: notes.D, octave: 2, duration: 2.5 }], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [{ note: notes.G, octave: 1, duration: 1 }, { note: notes.C, octave: 2, duration: 1 }, { note: notes.E, octave: 2, duration: 1 }], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [{ note: notes.F, octave: 1, duration: 1 }, { note: notes.A, octave: 1, duration: 1 }, { note: notes.C, octave: 2, duration: 1 }], 
  [], 
  [], 
  [], 
  [{ note: notes.F, octave: 1, duration: 1 }, { note: notes.A, octave: 1, duration: 1 }, { note: notes.C, octave: 2, duration: 1 }], 
  [], 
  [], 
  [], 
  [{ note: notes.C, octave: 0, duration: 1 }, { note: notes.F, octave: 1, duration: 1 }, { note: notes.A, octave: 1, duration: 1 }], 
  [], 
  [], 
  [],  
  [{ note: notes.C, octave: 0, duration: 6 }, { note: notes.F, octave: 1, duration: 6 }, { note: notes.G, octave: 1, duration: 6 }], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [],
  [], 
  [], 
  [], 
  [], 
  [{ note: notes.G, octave: 1, duration: 1 }, { note: notes.B, octave: 1, duration: 1 }, { note: notes.D, octave: 2, duration: 1 }], //4
  [], 
  [], 
  [], 
  [], 
  [], 
  [{ note: notes.G, octave: 1, duration: 1 }, { note: notes.C, octave: 2, duration: 1 }, { note: notes.E, octave: 2, duration: 1 }], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [{ note: notes.F, octave: 1, duration: 1 }, { note: notes.A, octave: 1, duration: 1 }, { note: notes.C, octave: 2, duration: 1 }], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [{ note: notes.F, octave: 1, duration: 1 }, { note: notes.A, octave: 1, duration: 1 }, { note: notes.C, octave: 2, duration: 1 }], 
  [], 
  [], 
  [], 
  [{ note: notes.G, octave: 1, duration: 1 }, { note: notes.B, octave: 1, duration: 1 }, { note: notes.D, octave: 2, duration: 1 }], 
  [], 
  [], 
  [], 
  [{ note: notes.G, octave: 1, duration: 2.5 }, { note: notes.B, octave: 1, duration: 2.5 }, { note: notes.D, octave: 2, duration: 2.5 }], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [{ note: notes.G, octave: 1, duration: 1 }, { note: notes.C, octave: 2, duration: 1 }, { note: notes.E, octave: 2, duration: 1 }], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [{ note: notes.F, octave: 1, duration: 1 }, { note: notes.A, octave: 1, duration: 1 }, { note: notes.C, octave: 2, duration: 1 }], 
  [], 
  [], 
  [], 
  [{ note: notes.F, octave: 1, duration: 1 }, { note: notes.A, octave: 1, duration: 1 }, { note: notes.C, octave: 2, duration: 1 }], 
  [], 
  [], 
  [], 
  [{ note: notes.C, octave: 0, duration: 1 }, { note: notes.F, octave: 1, duration: 1 }, { note: notes.A, octave: 1, duration: 1 }], 
  [], 
  [], 
  [],  
  [{ note: notes.C, octave: 0, duration: 6 }, { note: notes.F, octave: 1, duration: 6 }, { note: notes.G, octave: 1, duration: 6 }], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [],
  [], 
  [], 
  [], 
  [], 
  [{ note: notes.G, octave: 1, duration: 1 }, { note: notes.B, octave: 1, duration: 1 }, { note: notes.D, octave: 2, duration: 1 }], //4
  [], 
  [], 
  [], 
  [], 
  [], 
  [{ note: notes.G, octave: 1, duration: 1 }, { note: notes.C, octave: 2, duration: 1 }, { note: notes.E, octave: 2, duration: 1 }], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [{ note: notes.F, octave: 1, duration: 1 }, { note: notes.A, octave: 1, duration: 1 }, { note: notes.C, octave: 2, duration: 1 }], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [{ note: notes.F, octave: 1, duration: 1 }, { note: notes.A, octave: 1, duration: 1 }, { note: notes.C, octave: 2, duration: 1 }], 
  [], 
  [], 
  [], 
  [{ note: notes.G, octave: 1, duration: 1 }, { note: notes.B, octave: 1, duration: 1 }, { note: notes.D, octave: 2, duration: 1 }], 
  [], 
  [], 
  [], 
  [{ note: notes.G, octave: 1, duration: 2.5 }, { note: notes.B, octave: 1, duration: 2.5 }, { note: notes.D, octave: 2, duration: 2.5 }], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [{ note: notes.G, octave: 1, duration: 1 }, { note: notes.C, octave: 2, duration: 1 }, { note: notes.E, octave: 2, duration: 1 }], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [{ note: notes.F, octave: 1, duration: 1 }, { note: notes.A, octave: 1, duration: 1 }, { note: notes.C, octave: 2, duration: 1 }], 
  [], 
  [], 
  [], 
  [{ note: notes.F, octave: 1, duration: 1 }, { note: notes.A, octave: 1, duration: 1 }, { note: notes.C, octave: 2, duration: 1 }], 
  [], 
  [], 
  [], 
  [{ note: notes.C, octave: 0, duration: 1 }, { note: notes.F, octave: 1, duration: 1 }, { note: notes.A, octave: 1, duration: 1 }], 
  [], 
  [], 
  [],  
  [{ note: notes.C, octave: 0, duration: 6 }, { note: notes.F, octave: 1, duration: 6 }, { note: notes.G, octave: 1, duration: 6 }], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [{ note: notes.C, octave: 1, duration: 1 }, { note: notes.C, octave: 2, duration: 1 }], 
  [], 
  [{ note: notes.D, octave: 1, duration: 1 }, { note: notes.D, octave: 2, duration: 1 }], 
  [],
  [{ note: notes.E, octave: 1, duration: 1 }, { note: notes.E, octave: 2, duration: 1 }], 
  [], 
  [{ note: notes.D, octave: 1, duration: 1 }, { note: notes.D, octave: 2, duration: 1 }], 
  [], 
  [{ note: notes.E, octave: 1, duration: 1 }, { note: notes.E, octave: 2, duration: 1 }],
  [], 
  [{ note: notes.D, octave: 1, duration: 1 }, { note: notes.D, octave: 2, duration: 1 }], 
  [], 
  [{ note: notes.E, octave: 1, duration: 1 }, { note: notes.E, octave: 2, duration: 1 }], 
  [], 
  [{ note: notes.D, octave: 1, duration: 1 }, { note: notes.D, octave: 2, duration: 1 }], 
  [], 
  [{ note: notes.E, octave: 1, duration: 1 }, { note: notes.E, octave: 2, duration: 1 }],
  [], 
  [{ note: notes.D, octave: 1, duration: 1 }, { note: notes.D, octave: 2, duration: 1 }], 
  [], 
  [{ note: notes.E, octave: 1, duration: 1 }, { note: notes.E, octave: 2, duration: 1 }], 
  [], 
  [{ note: notes.D, octave: 1, duration: 1 }, { note: notes.D, octave: 2, duration: 1 }], 
  [], 
  [{ note: notes.E, octave: 1, duration: 1 }, { note: notes.E, octave: 2, duration: 1 }],
  [], 
  [{ note: notes.D, octave: 1, duration: 1 }, { note: notes.D, octave: 2, duration: 1 }], 
  [], 
  [{ note: notes.E, octave: 1, duration: 1 }, { note: notes.E, octave: 2, duration: 1 }], 
  [], 
  [{ note: notes.D, octave: 1, duration: 1 }, { note: notes.D, octave: 2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: 1, duration: 1 }, { note: notes.C, octave: 2, duration: 1 }],
  [], 
  [{ note: notes.C, octave: 1, duration: 3 }, { note: notes.C, octave: 2, duration: 3 }], 
  [], 
  [{ note: notes.F, octave: -1, duration: 1 }], 
  [], 
  [{ note: notes.A, octave: 1, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: 1, duration: 1 }], 
  [], 
  [{ note: notes.F, octave: 0, duration: 1 }], 
  [], 
  [{ note: notes.E, octave: 0, duration: 1 }], 
  [], 
  [{ note: notes.G, octave: 0, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: 1, duration: 1 }], 
  [], 
  [{ note: notes.E, octave: 1, duration: 3 }], 
  [], 
  [], 
  [], 
  [{ note: notes.D, octave: 1, duration: 3 }, { note: notes.D, octave: 2, duration: 3 }], 
  [], 
  [{ note: notes.E, octave: 1, duration: 1 }, { note: notes.E, octave: 2, duration: 1 }], 
  [], 
  [{ note: notes.D, octave: 1, duration: 1 }, { note: notes.D, octave: 2, duration: 1 }], 
  [], 
  [{ note: notes.E, octave: 1, duration: 1 }, { note: notes.E, octave: 2, duration: 1 }], 
  [], 
  [{ note: notes.D, octave: 1, duration: 1 }, { note: notes.D, octave: 2, duration: 1 }], 
  [], 
  [{ note: notes.E, octave: 1, duration: 1 }, { note: notes.E, octave: 2, duration: 1 }], 
  [], 
  [{ note: notes.E, octave: 1, duration: 2 }, { note: notes.E, octave: 2, duration: 2 }], 
  [], 
  [{ note: notes.F, octave: -1, duration: 1 }], 
  [], 
  [{ note: notes.A, octave: 1, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: 1, duration: 1 }], 
  [], 
  [{ note: notes.F, octave: 0, duration: 1 }], 
  [], 
  [{ note: notes.E, octave: 0, duration: 1 }], 
  [], 
  [{ note: notes.G, octave: 0, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: 1, duration: 1 }], 
  [], 
  [{ note: notes.E, octave: 1, duration: 3 }],
  [], 
  [], 
  [], 
  [{ note: notes.D, octave: 1, duration: 3 }, { note: notes.D, octave: 2, duration: 3 }], 
  [], 
  [{ note: notes.E, octave: 1, duration: 1 }, { note: notes.E, octave: 2, duration: 1 }], 
  [], 
  [{ note: notes.D, octave: 1, duration: 1 }, { note: notes.D, octave: 2, duration: 1 }], 
  [], 
  [{ note: notes.E, octave: 1, duration: 1 }, { note: notes.E, octave: 2, duration: 1 }], 
  [], 
  [{ note: notes.D, octave: 1, duration: 1 }, { note: notes.D, octave: 2, duration: 1 }], 
  [], 
  [{ note: notes.E, octave: 1, duration: 1 }, { note: notes.E, octave: 2, duration: 1 }], 
  [], 
  [{ note: notes.A, octave: 1, duration: 2 }, { note: notes.A, octave: 2, duration: 2 }], 
  [], 
  [{ note: notes.F, octave: -1, duration: 1 }], 
  [], 
  [{ note: notes.G, octave: 1, duration: 2 }, { note: notes.G, octave: 2, duration: 2 }], 
  [], 
  [{ note: notes.C, octave: 1, duration: 1 }], 
  [], 
  [{ note: notes.F, octave: 0, duration: 1 }], 
  [], 
  [{ note: notes.E, octave: 0, duration: 1 }], 
  [], 
  [{ note: notes.G, octave: 0, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: 1, duration: 1 }], 
  [], 
  [{ note: notes.A, octave: 1, duration: 3 }],
  [], 
  [], 
  [], 
  [{ note: notes.G, octave: 1, duration: 4 }], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [],
  [], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [{ note: notes.G, octave: 1, duration: 1 }, { note: notes.B, octave: 1, duration: 1 }, { note: notes.D, octave: 2, duration: 1 }], //4
  [], 
  [], 
  [], 
  [], 
  [], 
  [{ note: notes.G, octave: 1, duration: 1 }, { note: notes.C, octave: 2, duration: 1 }, { note: notes.E, octave: 2, duration: 1 }], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [{ note: notes.F, octave: 1, duration: 1 }, { note: notes.A, octave: 1, duration: 1 }, { note: notes.C, octave: 2, duration: 1 }], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [{ note: notes.F, octave: 1, duration: 1 }, { note: notes.A, octave: 1, duration: 1 }, { note: notes.C, octave: 2, duration: 1 }], 
  [], 
  [], 
  [], 
  [{ note: notes.G, octave: 1, duration: 1 }, { note: notes.B, octave: 1, duration: 1 }, { note: notes.D, octave: 2, duration: 1 }], 
  [], 
  [], 
  [], 
  [{ note: notes.G, octave: 1, duration: 2.5 }, { note: notes.B, octave: 1, duration: 2.5 }, { note: notes.D, octave: 2, duration: 2.5 }], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [{ note: notes.G, octave: 1, duration: 1 }, { note: notes.C, octave: 2, duration: 1 }, { note: notes.E, octave: 2, duration: 1 }], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [{ note: notes.F, octave: 1, duration: 1 }, { note: notes.A, octave: 1, duration: 1 }, { note: notes.C, octave: 2, duration: 1 }], 
  [], 
  [], 
  [], 
  [{ note: notes.F, octave: 1, duration: 1 }, { note: notes.A, octave: 1, duration: 1 }, { note: notes.C, octave: 2, duration: 1 }], 
  [], 
  [], 
  [], 
  [{ note: notes.C, octave: 0, duration: 1 }, { note: notes.F, octave: 1, duration: 1 }, { note: notes.A, octave: 1, duration: 1 }], 
  [], 
  [], 
  [],  
  [{ note: notes.C, octave: 0, duration: 6 }, { note: notes.F, octave: 1, duration: 6 }, { note: notes.G, octave: 1, duration: 6 }], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [{ note: notes.G, octave: 1, duration: 1 }, { note: notes.B, octave: 1, duration: 1 }, { note: notes.D, octave: 2, duration: 1 }], //4
  [], 
  [], 
  [], 
  [], 
  [], 
  [{ note: notes.G, octave: 1, duration: 1 }, { note: notes.C, octave: 2, duration: 1 }, { note: notes.E, octave: 2, duration: 1 }], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [{ note: notes.F, octave: 1, duration: 1 }, { note: notes.A, octave: 1, duration: 1 }, { note: notes.C, octave: 2, duration: 1 }], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [{ note: notes.F, octave: 1, duration: 1 }, { note: notes.A, octave: 1, duration: 1 }, { note: notes.C, octave: 2, duration: 1 }], 
  [], 
  [], 
  [], 
  [{ note: notes.G, octave: 1, duration: 1 }, { note: notes.B, octave: 1, duration: 1 }, { note: notes.D, octave: 2, duration: 1 }], 
  [], 
  [], 
  [], 
  [{ note: notes.G, octave: 1, duration: 2.5 }, { note: notes.B, octave: 1, duration: 2.5 }, { note: notes.D, octave: 2, duration: 2.5 }], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [{ note: notes.G, octave: 1, duration: 1 }, { note: notes.C, octave: 2, duration: 1 }, { note: notes.E, octave: 2, duration: 1 }], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [{ note: notes.F, octave: 1, duration: 1 }, { note: notes.A, octave: 1, duration: 1 }, { note: notes.C, octave: 2, duration: 1 }], 
  [], 
  [], 
  [], 
  [{ note: notes.F, octave: 1, duration: 1 }, { note: notes.A, octave: 1, duration: 1 }, { note: notes.C, octave: 2, duration: 1 }], 
  [], 
  [], 
  [], 
  [{ note: notes.C, octave: 0, duration: 1 }, { note: notes.F, octave: 1, duration: 1 }, { note: notes.A, octave: 1, duration: 1 }], 
  [], 
  [], 
  [],  
  [{ note: notes.C, octave: 0, duration: 6 }, { note: notes.F, octave: 1, duration: 6 }, { note: notes.G, octave: 1, duration: 6 }], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [{ note: notes.G, octave: 1, duration: 1 }, { note: notes.B, octave: 1, duration: 1 }, { note: notes.D, octave: 2, duration: 1 }], //4
  [], 
  [], 
  [], 
  [], 
  [], 
  [{ note: notes.G, octave: 1, duration: 1 }, { note: notes.C, octave: 2, duration: 1 }, { note: notes.E, octave: 2, duration: 1 }], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [{ note: notes.F, octave: 1, duration: 1 }, { note: notes.A, octave: 1, duration: 1 }, { note: notes.C, octave: 2, duration: 1 }], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [{ note: notes.F, octave: 1, duration: 1 }, { note: notes.A, octave: 1, duration: 1 }, { note: notes.C, octave: 2, duration: 1 }], 
  [], 
  [], 
  [], 
  [{ note: notes.G, octave: 1, duration: 1 }, { note: notes.B, octave: 1, duration: 1 }, { note: notes.D, octave: 2, duration: 1 }], 
  [], 
  [], 
  [], 
  [{ note: notes.G, octave: 1, duration: 2.5 }, { note: notes.B, octave: 1, duration: 2.5 }, { note: notes.D, octave: 2, duration: 2.5 }], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [{ note: notes.G, octave: 1, duration: 1 }, { note: notes.C, octave: 2, duration: 1 }, { note: notes.E, octave: 2, duration: 1 }], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [{ note: notes.F, octave: 1, duration: 1 }, { note: notes.A, octave: 1, duration: 1 }, { note: notes.C, octave: 2, duration: 1 }], 
  [], 
  [], 
  [], 
  [{ note: notes.F, octave: 1, duration: 1 }, { note: notes.A, octave: 1, duration: 1 }, { note: notes.C, octave: 2, duration: 1 }], 
  [], 
  [], 
  [], 
  [{ note: notes.C, octave: 0, duration: 1 }, { note: notes.F, octave: 1, duration: 1 }, { note: notes.A, octave: 1, duration: 1 }], 
  [], 
  [], 
  [],  
  [{ note: notes.C, octave: 0, duration: 6 }, { note: notes.F, octave: 1, duration: 6 }, { note: notes.G, octave: 1, duration: 6 }], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [],
  [], 
  [], 
  [], 
  [], 
  [{ note: notes.G, octave: 1, duration: 1 }, { note: notes.B, octave: 1, duration: 1 }, { note: notes.D, octave: 2, duration: 1 }], //4
  [], 
  [], 
  [], 
  [], 
  [], 
  [{ note: notes.G, octave: 1, duration: 1 }, { note: notes.C, octave: 2, duration: 1 }, { note: notes.E, octave: 2, duration: 1 }], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [{ note: notes.F, octave: 1, duration: 1 }, { note: notes.A, octave: 1, duration: 1 }, { note: notes.C, octave: 2, duration: 1 }], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [{ note: notes.F, octave: 1, duration: 1 }, { note: notes.A, octave: 1, duration: 1 }, { note: notes.C, octave: 2, duration: 1 }], 
  [], 
  [], 
  [], 
  [{ note: notes.G, octave: 1, duration: 1 }, { note: notes.B, octave: 1, duration: 1 }, { note: notes.D, octave: 2, duration: 1 }], 
  [], 
  [], 
  [], 
  [{ note: notes.G, octave: 1, duration: 2.5 }, { note: notes.B, octave: 1, duration: 2.5 }, { note: notes.D, octave: 2, duration: 2.5 }], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [{ note: notes.G, octave: 1, duration: 1 }, { note: notes.C, octave: 2, duration: 1 }, { note: notes.E, octave: 2, duration: 1 }], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [{ note: notes.F, octave: 1, duration: 1 }, { note: notes.A, octave: 1, duration: 1 }, { note: notes.C, octave: 2, duration: 1 }], 
  [], 
  [], 
  [], 
  [{ note: notes.F, octave: 1, duration: 1 }, { note: notes.A, octave: 1, duration: 1 }, { note: notes.C, octave: 2, duration: 1 }], 
  [], 
  [], 
  [], 
  [{ note: notes.C, octave: 0, duration: 1 }, { note: notes.F, octave: 1, duration: 1 }, { note: notes.A, octave: 1, duration: 1 }], 
  [], 
  [], 
  [],  
  [{ note: notes.C, octave: 0, duration: 6 }, { note: notes.F, octave: 1, duration: 6 }, { note: notes.G, octave: 1, duration: 6 }], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [{ note: notes.C, octave: 1, duration: 1 }, { note: notes.C, octave: 2, duration: 1 }], 
  [], 
  [{ note: notes.D, octave: 1, duration: 1 }, { note: notes.D, octave: 2, duration: 1 }], 
  [],
  [{ note: notes.E, octave: 1, duration: 1 }, { note: notes.E, octave: 2, duration: 1 }], 
  [], 
  [{ note: notes.D, octave: 1, duration: 1 }, { note: notes.D, octave: 2, duration: 1 }], 
  [], 
  [{ note: notes.E, octave: 1, duration: 1 }, { note: notes.E, octave: 2, duration: 1 }],
  [], 
  [{ note: notes.D, octave: 1, duration: 1 }, { note: notes.D, octave: 2, duration: 1 }], 
  [], 
  [{ note: notes.E, octave: 1, duration: 1 }, { note: notes.E, octave: 2, duration: 1 }], 
  [], 
  [{ note: notes.D, octave: 1, duration: 1 }, { note: notes.D, octave: 2, duration: 1 }], 
  [], 
  [{ note: notes.E, octave: 1, duration: 1 }, { note: notes.E, octave: 2, duration: 1 }],
  [], 
  [{ note: notes.D, octave: 1, duration: 1 }, { note: notes.D, octave: 2, duration: 1 }], 
  [], 
  [{ note: notes.E, octave: 1, duration: 1 }, { note: notes.E, octave: 2, duration: 1 }], 
  [], 
  [{ note: notes.D, octave: 1, duration: 1 }, { note: notes.D, octave: 2, duration: 1 }], 
  [], 
  [{ note: notes.E, octave: 1, duration: 1 }, { note: notes.E, octave: 2, duration: 1 }],
  [], 
  [{ note: notes.D, octave: 1, duration: 1 }, { note: notes.D, octave: 2, duration: 1 }], 
  [], 
  [{ note: notes.E, octave: 1, duration: 1 }, { note: notes.E, octave: 2, duration: 1 }], 
  [], 
  [{ note: notes.D, octave: 1, duration: 1 }, { note: notes.D, octave: 2, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: 1, duration: 1 }, { note: notes.C, octave: 2, duration: 1 }],
  [], 
  [{ note: notes.C, octave: 1, duration: 3 }, { note: notes.C, octave: 2, duration: 3 }], 
  [], 
  [{ note: notes.F, octave: -1, duration: 1 }], 
  [], 
  [{ note: notes.A, octave: 1, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: 1, duration: 1 }], 
  [], 
  [{ note: notes.F, octave: 0, duration: 1 }], 
  [], 
  [{ note: notes.E, octave: 0, duration: 1 }], 
  [], 
  [{ note: notes.G, octave: 0, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: 1, duration: 1 }], 
  [], 
  [{ note: notes.E, octave: 1, duration: 3 }], 
  [], 
  [], 
  [], 
  [{ note: notes.D, octave: 1, duration: 3 }, { note: notes.D, octave: 2, duration: 3 }], 
  [], 
  [{ note: notes.E, octave: 1, duration: 1 }, { note: notes.E, octave: 2, duration: 1 }], 
  [], 
  [{ note: notes.D, octave: 1, duration: 1 }, { note: notes.D, octave: 2, duration: 1 }], 
  [], 
  [{ note: notes.E, octave: 1, duration: 1 }, { note: notes.E, octave: 2, duration: 1 }], 
  [], 
  [{ note: notes.D, octave: 1, duration: 1 }, { note: notes.D, octave: 2, duration: 1 }], 
  [], 
  [{ note: notes.E, octave: 1, duration: 1 }, { note: notes.E, octave: 2, duration: 1 }], 
  [], 
  [{ note: notes.E, octave: 1, duration: 2 }, { note: notes.E, octave: 2, duration: 2 }], 
  [], 
  [{ note: notes.F, octave: -1, duration: 1 }], 
  [], 
  [{ note: notes.A, octave: 1, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: 1, duration: 1 }], 
  [], 
  [{ note: notes.F, octave: 0, duration: 1 }], 
  [], 
  [{ note: notes.E, octave: 0, duration: 1 }], 
  [], 
  [{ note: notes.G, octave: 0, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: 1, duration: 1 }], 
  [], 
  [{ note: notes.E, octave: 1, duration: 3 }],
  [], 
  [], 
  [], 
  [{ note: notes.D, octave: 1, duration: 3 }, { note: notes.D, octave: 2, duration: 3 }], 
  [], 
  [{ note: notes.E, octave: 1, duration: 1 }, { note: notes.E, octave: 2, duration: 1 }], 
  [], 
  [{ note: notes.D, octave: 1, duration: 1 }, { note: notes.D, octave: 2, duration: 1 }], 
  [], 
  [{ note: notes.E, octave: 1, duration: 1 }, { note: notes.E, octave: 2, duration: 1 }], 
  [], 
  [{ note: notes.D, octave: 1, duration: 1 }, { note: notes.D, octave: 2, duration: 1 }], 
  [], 
  [{ note: notes.E, octave: 1, duration: 1 }, { note: notes.E, octave: 2, duration: 1 }], 
  [], 
  [{ note: notes.A, octave: 1, duration: 2 }, { note: notes.A, octave: 2, duration: 2 }], 
  [], 
  [{ note: notes.F, octave: -1, duration: 1 }], 
  [], 
  [{ note: notes.G, octave: 1, duration: 2 }, { note: notes.G, octave: 2, duration: 2 }], 
  [], 
  [{ note: notes.C, octave: 1, duration: 1 }], 
  [], 
  [{ note: notes.F, octave: 0, duration: 1 }], 
  [], 
  [{ note: notes.E, octave: 0, duration: 1 }], 
  [], 
  [{ note: notes.G, octave: 0, duration: 1 }], 
  [], 
  [{ note: notes.C, octave: 1, duration: 1 }], 
  [], 
  [{ note: notes.A, octave: 1, duration: 3 }],
  [], 
  [], 
  [], 
  [{ note: notes.G, octave: 1, duration: 4 }], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [],
  [], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [{ note: notes.G, octave: 1, duration: 1 }, { note: notes.B, octave: 1, duration: 1 }, { note: notes.D, octave: 2, duration: 1 }], //4
  [], 
  [], 
  [], 
  [], 
  [], 
  [{ note: notes.G, octave: 1, duration: 1 }, { note: notes.C, octave: 2, duration: 1 }, { note: notes.E, octave: 2, duration: 1 }], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [{ note: notes.F, octave: 1, duration: 1 }, { note: notes.A, octave: 1, duration: 1 }, { note: notes.C, octave: 2, duration: 1 }], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [{ note: notes.F, octave: 1, duration: 1 }, { note: notes.A, octave: 1, duration: 1 }, { note: notes.C, octave: 2, duration: 1 }], 
  [], 
  [], 
  [], 
  [{ note: notes.G, octave: 1, duration: 1 }, { note: notes.B, octave: 1, duration: 1 }, { note: notes.D, octave: 2, duration: 1 }], 
  [], 
  [], 
  [], 
  [{ note: notes.G, octave: 1, duration: 2.5 }, { note: notes.B, octave: 1, duration: 2.5 }, { note: notes.D, octave: 2, duration: 2.5 }], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [{ note: notes.G, octave: 1, duration: 1 }, { note: notes.C, octave: 2, duration: 1 }, { note: notes.E, octave: 2, duration: 1 }], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [{ note: notes.F, octave: 1, duration: 1 }, { note: notes.A, octave: 1, duration: 1 }, { note: notes.C, octave: 2, duration: 1 }], 
  [], 
  [], 
  [], 
  [{ note: notes.F, octave: 1, duration: 1 }, { note: notes.A, octave: 1, duration: 1 }, { note: notes.C, octave: 2, duration: 1 }], 
  [], 
  [], 
  [], 
  [{ note: notes.C, octave: 0, duration: 1 }, { note: notes.F, octave: 1, duration: 1 }, { note: notes.A, octave: 1, duration: 1 }], 
  [], 
  [], 
  [],  
  [{ note: notes.C, octave: 0, duration: 6 }, { note: notes.F, octave: 1, duration: 6 }, { note: notes.G, octave: 1, duration: 6 }], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [{ note: notes.G, octave: 1, duration: 1 }, { note: notes.B, octave: 1, duration: 1 }, { note: notes.D, octave: 2, duration: 1 }], //4
  [], 
  [], 
  [], 
  [], 
  [], 
  [{ note: notes.G, octave: 1, duration: 1 }, { note: notes.C, octave: 2, duration: 1 }, { note: notes.E, octave: 2, duration: 1 }], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [{ note: notes.F, octave: 1, duration: 1 }, { note: notes.A, octave: 1, duration: 1 }, { note: notes.C, octave: 2, duration: 1 }], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [{ note: notes.F, octave: 1, duration: 1 }, { note: notes.A, octave: 1, duration: 1 }, { note: notes.C, octave: 2, duration: 1 }], 
  [], 
  [], 
  [], 
  [{ note: notes.G, octave: 1, duration: 1 }, { note: notes.B, octave: 1, duration: 1 }, { note: notes.D, octave: 2, duration: 1 }], 
  [], 
  [], 
  [], 
  [{ note: notes.G, octave: 1, duration: 2.5 }, { note: notes.B, octave: 1, duration: 2.5 }, { note: notes.D, octave: 2, duration: 2.5 }], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [{ note: notes.G, octave: 1, duration: 1 }, { note: notes.C, octave: 2, duration: 1 }, { note: notes.E, octave: 2, duration: 1 }], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [{ note: notes.F, octave: 1, duration: 1 }, { note: notes.A, octave: 1, duration: 1 }, { note: notes.C, octave: 2, duration: 1 }], 
  [], 
  [], 
  [], 
  [{ note: notes.F, octave: 1, duration: 1 }, { note: notes.A, octave: 1, duration: 1 }, { note: notes.C, octave: 2, duration: 1 }], 
  [], 
  [], 
  [], 
  [{ note: notes.C, octave: 0, duration: 1 }, { note: notes.F, octave: 1, duration: 1 }, { note: notes.A, octave: 1, duration: 1 }], 
  [], 
  [], 
  [],  
  [{ note: notes.C, octave: 0, duration: 6 }, { note: notes.F, octave: 1, duration: 6 }, { note: notes.G, octave: 1, duration: 6 }], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [], 
  [],
  [], 
];