import React from 'react';

const Footer = () => (
  <footer>
    <div className="container">
      <p>&copy; JSON Karaoke Machine 2019. All Rights Reserved.</p>
    </div>
  </footer>
);

export default Footer;
